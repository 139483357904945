import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


function Stars(props) {
  const [value, setValue] = React.useState(2);
  return (
    <div >
      <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend"></Typography>
        <Rating name="read-only" value={5} readOnly />
      </Box>
    </div>
  );
}

export default Stars;