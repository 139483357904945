import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logout, update } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../actions/orderAction";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loading from "../components/Loading"
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";


import MaterialTable from "material-table";


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});
function ProfileScreen(props) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  const handleLogout = () => {
    dispatch(logout());
    props.history.push("/signin");
  };

const classes = useStyles();

const [state, setState] = React.useState({
  columns: [
    { title: "Order-Id", field: "orderId" },
    { title: "Date", field:"createdAt" },
    { title: "Total", field: "totalPrice" },
    { title: "Paid", field: "isPaid" },
    { title: "Paid At", field: "paidAt" },
    { title: "Delivered", field: "isDelivered" },
    { title: "Delivered At", field:"deliveredAt" },
    {
      title: "Action",
      render: data => (
        <div class="flex">
          <Link to={"/order/" + data.orderId}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Icon>details</Icon>}
              className={classes.button}
            >
              Details
            </Button>
                 
          </Link>
        </div>
      )
    }
  ]
});


  const submitHandler = e => {
    e.preventDefault();
    dispatch(update({ userId: userInfo._id, email, name, password }));
    props.history.push("/profile");
  };

  const userUpdate = useSelector(state => state.userUpdate);
  const { loading, success, error } = userUpdate;

  const myOrderList = useSelector(state => state.myOrderList);
  const { loading: loadingOrders, orders, error: errorOrders } = myOrderList;
  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setPassword(userInfo.password);
      setEmail(userInfo.email);
    }
    dispatch(listMyOrders());
    return () => {};
  }, []);

  return (
    <div className="profile table-list screen-content">
      {loadingOrders && loading?<Loading/>:
      errorOrders && error?<div>Error fetching data</div>:
     <div className="profile-info">
     <div className="form">
       <form onSubmit={submitHandler}>
         <ul className="form-container">
           <li>
             <h2>User Profile</h2>
           </li>
           <li>
             {loading && <div>Loading...</div>}
             {error && <div>{error}</div>}
             {success && <div>Profile Saved Successfully </div>}
           </li>
           <li>
             <label htmlFor="name">Name</label>
             <input
               value={name}
               type="name"
               name="name"
               id="name"
               onChange={e => setName(e.target.value)}
             ></input>
           </li>
           <li>
             <label htmlFor="email">Email</label>
             <input
               value={email}
               type="email"
               name="email"
               id="email"
               onChange={e => setEmail(e.target.value)}
             ></input>
           </li>
           <li>
             <label htmlFor="password">Password</label>
             <input
               value={password}
               type="password"
               id="password"
               name="password"
               onChange={e => setPassword(e.target.value)}
             ></input>
           </li>
           <li>
             <button type="submit" className="button primary">
               Update
             </button>
           </li>
           <li>
             <button
               type="button"
               onClick={handleLogout}
               className="button secondary full-width"
             >
               Logout
             </button>
           </li>
         </ul>
       </form>
     </div>
   </div>
  }
    </div>
  );
}

export default ProfileScreen;
