import React, { useState, useEffect, useRef } from "react";
import { createBrowserHistory } from "history";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { listProducts } from "../actions/productAction";
import SearchDropDown from "../components/SearchDropDown";
import {setKeyWord} from '../global/SharedData';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";


import SideBar from "./SideBarMenu";

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    },
    fontSize: "2rem"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    // color:'red'
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "36ch"
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  button: {
    "& > *": {
      margin: theme.spacing(1)
    },
    color: "white",
    borderStyle: "solid",
    borderColor: "white"
  },
  buttonMobile: {
    "& > *": {
      margin: theme.spacing(1)
    },
    color: "black",
    borderStyle: "solid",
    borderColor: "black"
  }
}));

export default function MenuBar(props) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const category = props.match ? props.match.params.id : "";

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const dispatch = useDispatch();

  useEffect(() => {
    handleFilter();
  }, [searchKeyword]);
  const dropDownList = useRef();
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.addEventListener('click',(event)=>{
      event.stopPropagation();
      dropDownList.current.style.display = 'flex';
    });
    document.addEventListener('click',(event)=>{
      dropDownList.current.style.display = 'none';
    })
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  const search = keyWord => {
    const history = createBrowserHistory();
    const pathName = history.location.pathname;
    if (pathName !== "/") {
      setSearchKeyword(keyWord);
    }
    setSearchKeyword(keyWord);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const pageNumber = 1;
  const handleFilter = () => {
    setKeyWord(searchKeyword);
    dispatch(listProducts(pageNumber, category, searchKeyword));
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <Link to="/profile" className="color_black">
        <MenuItem onClick={handleMenuClose}>
          <span className="header__optionLinetwo color_black">Profile</span>
        </MenuItem>
      </Link> */} 
      <MenuItem onClick={handleLogout}>
        <span className="header__optionLinetwo color_black">Sign-out</span>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <p>
          <div className="header__option">
            {!userInfo ? (
              <Link to="signin" className="white__link">
                <Link to="signin" className="white__link">
                  <Button variant="outlined" className={classes.buttonMobile}>
                    Sign in
                  </Button>
                </Link>
              </Link>
            ) : (
              <span className="header__optionLineone color_black">
                Hallo {userInfo.name},
              </span>
            )}

            {userInfo && (
              <span className="header__optionLinetwo color_black">Welcome</span>
            )}
          </div>
        </p>
      </MenuItem>
      {userInfo && (
        <MenuItem>
          <p>
            <div className="header__option">
              <Link to="/signin">
                <Button
                  variant="outlined"
                  className={classes.buttonMobile}
                  onClick={handleLogout}
                >
                  Sign Out
                </Button>
              </Link>
            </div>
          </p>
        </MenuItem>
      )}
      <Link to="/cart" className="cart">
        <div>
          <IconButton></IconButton>
          <IconButton onClick={handleMenuClose} aria-label="show 4 new mails">
            <div className="header__optionBasket">
              <ShoppingBasketIcon />
              <span className="header__optionLinetwo header__basketCount">
                {cartItems.length}
              </span>
            </div>
          </IconButton>
        </div>
      </Link>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <nav className="header">
        <AppBar position="static" style={{ backgroundColor: "#131921" }}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <SideBar />
            </IconButton>
            <Link to="/" className="color_turqois">
              <Typography className={classes.title} variant="h6" noWrap>
                SURIWEBWINKEL
              </Typography>
            </Link>
            <div className={classes.search} ref={inputRef}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Zoeken..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={e => search(e.target.value)}
              />
              <div className="dropdown" ref={dropDownList}>
                {searchKeyword !== "" ? <SearchDropDown /> : ""}
              </div>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <div className="header__option">
                  {!userInfo ? (
                    <Link to="signin" className="white__link">
                      <Button variant="outlined" className={classes.button}>
                      Sign in
                    </Button>
                    </Link>
                  ) : (
                    <span className="header__optionLineone">
                      Hallo {userInfo.name},
                    </span>
                  )}

                  {userInfo && (
                    <span className="header__optionLinetwo">Welcome</span>
                  )}
                </div>
              </IconButton>
              {userInfo && (
              <IconButton aria-label="show 4 new mails" color="inherit">
                <div className="header__option">
                <Link to="/signin">
                    <Button variant="outlined" className={classes.button} onClick={handleLogout}>
                      Sign Out
                    </Button>
                    </Link>
                </div>
              </IconButton>
              )}

              <IconButton aria-label="show 4 new mails" color="inherit">
                <Link to="/cart" className="header__link">
                  <div className="header__optionBasket">
                    <ShoppingBasketIcon />
                    <span className="header__optionLinetwo header__basketCount">
                      {cartItems && cartItems.length}
                    </span>
                  </div>
                </Link>
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </nav>
    </div>
  );
}
