import { RATE_LIST_REQUEST, RATE_LIST_SUCCESS, RATE_LIST_FAIL, RATE_LATEST_REQUEST, RATE_LATEST_SUCCESS, RATE_LATEST_FAIL } from "../constants/rateConstants";


function rateListReducer(state = { rates: [] }, action){
    switch (action.type) {
        case RATE_LIST_REQUEST:
          return { loading: true,rates:[] };
        case RATE_LIST_SUCCESS:
          return {
            loading: false,
            success: true,
            rates: action.payload
          };
        case RATE_LIST_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
}

function rateLatestReducer(state = { latestRate: {} }, action){
  switch (action.type) {
    case RATE_LATEST_REQUEST:
      return { loading: true,latestRate:{} };
    case RATE_LATEST_SUCCESS:
      return {
        loading: false,
        success:true,
        latestRate: action.payload,
      };
    case RATE_LATEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {rateListReducer, rateLatestReducer};