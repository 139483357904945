import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USERS_ALL_REQUEST,
  USERS_ALL_SUCCESS,
  USERS_ALL_FAIL,
  USER_BLOCK_REQUEST,
  USERS_BLOCK_SUCCESS,
  USERS_BLOCK_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAIL
} from "../constants/userConstants";

function userSigninReducer(state = {}, action) {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload, status: action.status };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

function userVerifyReducer(state={},action) {
  switch (action.type) {
    case USER_VERIFY_REQUEST:
      return { loading: true };
    case USER_VERIFY_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_VERIFY_FAIL:
      return { loading: false, error: action.payload, status: action.status };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

function allUsersReducer(state = { users: [] }, action) {
  switch (action.type) {
    case USERS_ALL_REQUEST:
      return { loading: true };
    case USERS_ALL_SUCCESS:
      return { loading: false, users: action.payload };
    case USERS_ALL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function blockUserReducer(state = {}, action) {
  switch (action.type) {
    case USER_BLOCK_REQUEST:
      return { loading: true };
    case USERS_BLOCK_SUCCESS:
      return { loading: false, success:true, blockInfo: action.payload };
    case USERS_BLOCK_FAIL:
      return { loading: false, error: action.payload };
      default:
        return state;
  }
}

function userUpdateReducer(state = {}, action) {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userRegisterReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success:true, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
  allUsersReducer,
  blockUserReducer,
  userVerifyReducer
};
