import {
    PAYMENT_GET_PAYMENT_CLIENT_SECRET_FAIL,
    PAYMENT_GET_PAYMENT_CLIENT_SECRET_SUCCESS,
    PAYMENT_GET_PAYMENT_CLIENT_SECRET_REQUEST
  } from "../constants/paymentConstants";

  function getPaymentClientSecretReducer(state={},action){
    switch (action.type) {
        case PAYMENT_GET_PAYMENT_CLIENT_SECRET_REQUEST:
          return { loading: true };
        case PAYMENT_GET_PAYMENT_CLIENT_SECRET_SUCCESS:
          return { loading: false, paymentClientSecret: action.payload, success:true };
        case PAYMENT_GET_PAYMENT_CLIENT_SECRET_FAIL:
          return { loading: false, error: action.payload };
          default:
            return state;
      }

  }

  export{
    getPaymentClientSecretReducer
  }