import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Stars from "../components/Stars";
import Fade from "react-reveal";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { addToCart } from "../actions/cartAction";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(32),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  cardMedia: {
    paddingTop: "100%", // 16:9 --> 56.25%
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  button: {
    borderRadius: 50,
    margin: "8px",
    width: "96%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  price: {
    justifyContent: "left",
    textAlign: "left",
    fontWeight: "bold",
  },
  star: {
    float: "right",
    marginBottom: 0,
  },
  category: {
    fontWeight: "normal",
  },
  options: {
    borderRadius: 50,
    margin: "8px",
    width: "96%",
    paddingLeft: "2rem",
  },
  unit: {
    flex: 1,
    float: "right",
    marginBottom: 0,
  },
  wrapper: {
    display: "flex",
  },
}));

export default function ProductV2(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const products = props.data;

  const convertSrdToEuro = (srd, euro) => {
    return `€ ${(srd / euro).toFixed(2)}`;
  };

  const handleAddToCart = (productId, productName, variant) => {
    dispatch(addToCart(productId, 1));
    enqueueSnackbar(`${productName} toegevoed in mandje`, { variant });
  };

  return (
    <div className="screen-content">
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {products ? (
            products &&
            products.map((product) => (
              <Grid item key={product._id} xs={12} sm={6} md={4}>
                <Fade bottom cascasde>
                  <Card className={classes.card}>
                    <Link
                      underline="none"
                      component={RouterLink}
                      to={`/product/${product._id}`}
                    >
                      <CardMedia
                        className={classes.cardMedia}
                        image={product.image}
                      />
                    </Link>
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        <Link
                          underline="none"
                          component={RouterLink}
                          to={`/product/${product._id}`}
                        >
                          {product.name}
                        </Link>
                      </Typography>
                      <Typography
                        className={classes.category}
                        variant="h6"
                        component="h2"
                      >
                        {product.category}
                      </Typography>
                      <Typography>
                        {product.description.length > 48
                          ? product.description.substring(0, 35) + "..."
                          : product.description}
                      </Typography>
                      <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        color="textPrimary"
                        className={classes.price}
                      >
                        {convertSrdToEuro(product.price, props.rateEuro)}
                        <div className={classes.wrapper}>
                          <Typography className={classes.unit}>
                            {product.unit && "Per " + product.unit}
                          </Typography>
                          <Stars />
                        </div>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        type="submit"
                        className={classes.button}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleAddToCart.bind(
                          this,
                          product._id,
                          product.name,
                          "success"
                        )}
                      >
                        Toevoegen in winkelmand
                      </Button>
                    </CardActions>
                  </Card>
                </Fade>
              </Grid>
            ))
          ) : (
            <div>Data is loading...</div>
          )}
        </Grid>
      </Container>
    </div>
  );
}
