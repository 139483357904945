import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listUsers, blockUser } from "../actions/userActions";
import Loading from "../components/Loading";

import MaterialTable from "material-table";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

export default function UsersScreen() {
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [transition, setTransition] = React.useState(undefined);

  var [checked, setChecked] = useState();
  const isCheckedRef = React.useRef(checked)

  const allUserList = useSelector(state => state.allUsers);
  const { users, loading, error } = allUserList;

  const blockUserInfo = useSelector(state => state.blockUser);
  const {
    blockInfo,
    loading: blockLoading,
    error: blockError,
    success: successBlock
  } = blockUserInfo;

  const handleClick = Transition => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listUsers());
    return () => {};
  }, [successBlock]);

  const toggleChecked = user => {
    dispatch(blockUser(user._id));
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    console.log(state);
  };

  const handleBlock = (user)=>{
    setChecked(user.status == 'ACTIVE'?false:true);
    // dispatch(blockUser(user._id));
    if(checked){
      setChecked(current => !current);
    }else{
      setChecked(current => !current);
    }
  };

  const [state, setState] = React.useState({
    checked:true,
    columns: [
      { title: "Id", field: "._id" },
      { title: "Name", field: "name" },
      { title: "Email", field: "email" },
      { title: "Phone Number", field: "phoneNumber" },
      {
        title: "Status",
        render: data => (
          <Chip
            icon={
              data.status === "ACTIVE" ? <CheckCircleIcon /> : <BlockIcon />
            }
            label={data.status}
            clickable
            color={data.status === "ACTIVE" ? "primary" : "secondary"}
            deleteIcon={<DoneIcon />}
          />
        )
      },
      {
        title: "Action",
        render: data => (
          <div>
         <Switch name= "checked" checked={data.status == 'ACTIVE'?false:true} inputProps={{ 'aria-label': 'primary checkbox' }} onChange={()=>toggleChecked(data)}  />
      </div>
        )
      }
    ]
  });

  const classes = useStyles();
  return loading || blockLoading ? (
    <div className="loading">
      <Loading />
    </div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div className="table-list table-only">
      <MaterialTable
        title="All Users"
        columns={state.columns}
        data={users}
        options={{ 
          exportButton: true,
          exportAllData:true,
          searchAutoFocus:true,
          maxBodyHeight:430,
          pageSize:20,
          pageSizeOptions:[20,40,100]
         }}
      />
    </div>
  );
}
