import React from "react";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import { listRates } from "../actions/rateAction";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Axios from 'axios';
import moment from 'moment';
import 'moment/locale/nl';


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    tableLayout: "fixed",
    whiteSpace: "nowrap"
  },
  button: {
    margin: theme.spacing(1)
  },
  createProduct: {
    margin: "3rem"
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));


const convertDate = (date)=>{
  const timeStamp = Date.parse(date);
  const momentStamp = moment(timeStamp);
  return momentStamp.add(7,'hours').format('LLLL');
}


function RatesScreen(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [euro,setEuro] = React.useState('');
  const [usd,setUsd] = React.useState('');
  const [update,setUpdate] = React.useState(false);

  const [state, setState] = React.useState({
    columns: [
      { title: "Euro", field: "euro" },
      { title: "USD", field:"usd" },
      { title: "Created At", field:"createdAt",render: data=>(
        <div>{convertDate(data.createdAt)}</div>
      ) },
    ]
  });

  const userSignin = useSelector(state => state.userSignin);
  const { loading, userInfo, error } = userSignin;

  const rateList = useSelector(state => state.rateList)
  const {loading: loadingRates, success, rates } = rateList;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(listRates());
    return () => {
      //
    };
  }, [userInfo,update]);

  const submitHandler = async(e) => {
    e.preventDefault();
    const body = {
      usd,
      euro
    }
    const data = await Axios.post('/api/rate/',body,{
      headers: {
        Authorization: "Bearer " + userInfo.token
      }
    });
    if(data.status === 201){
     setUpdate(true);
     handleClose();
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <form className={classes.root} noValidate autoComplete="off">
        <h3>Add new rate</h3>
        <div>
        <TextField id="outlined-search" type="number" label="Euro" onChange={e => setEuro(e.target.value)} variant="outlined" />
        </div>
        <div>
        <TextField id="outlined-search2" type="number" label="USD" onChange={e => setUsd(e.target.value)} variant="outlined" />
        </div>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.createProduct}
            onClick={submitHandler}
          >
            Add
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div className="screen-content">
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.createProduct}
          onClick={handleOpen}
        >
          Add new rate
        </Button>
        <div className={classes.popup}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        </div>
      </div>
      <MaterialTable
        title="All Rates"
        columns={state.columns}
        data={rates}
        options={{ exportButton: true }}
      />
    </div>
  );
}

export default RatesScreen;
