import Axios from "../configuration/axiosConfiguration";
import {
  RATE_LIST_REQUEST,
  RATE_LIST_SUCCESS,
  RATE_LIST_FAIL,
  RATE_LATEST_SUCCESS,
  RATE_LATEST_FAIL,
  RATE_LATEST_REQUEST,
} from "../constants/rateConstants";

const listRates = () => async (dispatch, getState) => {
  try {
    dispatch({ type: RATE_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get("/api/rate/", {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: RATE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RATE_LIST_FAIL, payload: error.message });
  }
};

const getLatestRate = () => async (dispatch, getState) => {
  try {
    dispatch({ type: RATE_LATEST_REQUEST });
    const { data } = await Axios.get("/api/rate/latest");
    dispatch({ type: RATE_LATEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RATE_LATEST_FAIL });
  }
};

export { listRates, getLatestRate };
