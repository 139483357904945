export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCES";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const MY_ORDER_LIST_REQUEST = "MY_ORDER_LIST_REQUEST";
export const MY_ORDER_LIST_SUCCESS = "MY_ORDER_LIST_SUCCES";
export const MY_ORDER_LIST_FAIL = "MY_ORDER_LIST_FAIL";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";

export const ORDER_CHANGESTATUS_REQUEST = "ORDER_CHANGESTATUS_REQUEST";
export const ORDER_CHANGESTATUS_SUCCESS = "ORDER_CHANGESTATUS_SUCCESS";
export const ORDER_CHANGESTATUS_FAIL = "ORDER_CHANGESTATUS_FAIL";
