import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logout, update } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../actions/orderAction";

import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loading from "../components/Loading"
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import moment from 'moment';
import 'moment/locale/nl';

import MaterialTable from "material-table";


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});
function MyOrderScreen(props) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  const handleLogout = () => {
    dispatch(logout());
    props.history.push("/signin");
  };

  const convertDate = (date)=>{
    const timeStamp = Date.parse(date);
    const momentStamp = moment(timeStamp);
    return momentStamp.add(7,'hours').format('LLLL');
  }

const classes = useStyles();

const [state, setState] = React.useState({
  columns: [
    { title: "Order-Id", field: "orderId" },
    { title: "Bestel Datum", field:"createdAt", render: data=>(
      <div>{convertDate(data.createdAt)}</div>
    ) },
    { title: "Totaal", field: "totalPrice" },
    { title: "Betaal Status", field: "isPaid", render: data=>(
      <Chip
          icon={data.isPaid === true?<CheckCircleIcon />:<CancelIcon/>}
          label={data.isPaid === true?'Betaald':'Niet Betaald'}
          clickable
          color={
            data.isPaid === true
              ? "primary"
              : "secondary"
          }
          deleteIcon={<DoneIcon />}
        />
    ) },
    { title: "Betaald op", field: "paidAt",  render: data=>(
      <div>{data.paidAt?convertDate(data.paidAt):''}</div>
    )  },
    { title: "Afgeleverd", field: "isDelivered" },
    { title: "Afgeleverd op", field:"deliveredAt", render: data=>(
      <div>{data.deliveredAt?convertDate(data.deliveredAt):''}</div>
    ) },
    {
        title: "Status",
        render: data => (
          <Chip
            icon={data.orderStatus === 'DELIVERED'?<CheckCircleIcon />:data.orderStatus === 'PENDING'?<HourglassFullIcon/>:<ShoppingCartIcon/>}
            label={data.orderStatus}
            clickable
            color={
              data.orderStatus === "DELIVERED"
                ? "primary"
                : data.orderStatus === "PENDING"
                ? "secondary"
                : ""
            }
            deleteIcon={<DoneIcon />}
          />
        ),
        field: 'orderStatus',

      },
    {
      title: "Action",
      render: data => (
        <div class="flex">
          <Link to={"/order/" + data.orderId}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Icon>details</Icon>}
              className={classes.button}
            >
                Overzicht
            </Button>
                 
          </Link>
        </div>
      )
    }
  ]
});


  const submitHandler = e => {
    e.preventDefault();
    dispatch(update({ userId: userInfo._id, email, name, password }));
    props.history.push("/profile");
  };

  const userUpdate = useSelector(state => state.userUpdate);
  const { loading, success, error } = userUpdate;

  const myOrderList = useSelector(state => state.myOrderList);
  const { loading: loadingOrders, orders, error: errorOrders } = myOrderList;
  useEffect(() => {
    if(!userInfo){
      props.history.push('/signin?redirect=myorders')
    }
    if (userInfo) {
      setName(userInfo.name);
      setPassword(userInfo.password);
      setEmail(userInfo.email);
    }
    dispatch(listMyOrders());
    return () => {};
  }, []);

  return (
    
      
      <div className="">
        {loadingOrders ? (
          <div><Loading/></div>
        ) : errorOrders ? (
          <div>{errorOrders}</div>
        ) : (
          <div className="table-only">
      <MaterialTable title="My Orders" columns={state.columns} data={orders} options={{exportButton:true}}/>
        </div>
        )}
      </div>
    
  );
}

export default MyOrderScreen;
