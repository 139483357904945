export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL= 'USER_SIGNIN_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL= 'USER_REGISTER_FAIL';

export const USER_UPDATE_REQUEST= 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL= 'USER_UPDATE_FAIL';

export const USERS_ALL_REQUEST= 'USERS_ALL_REQUEST';
export const USERS_ALL_SUCCESS = 'USERS_ALL_SUCCESS';
export const USERS_ALL_FAIL= 'USERS_ALL_FAIL';

export const USER_BLOCK_REQUEST= 'USER_BLOCK_REQUEST';
export const USERS_BLOCK_SUCCESS = 'USERS_BLOCK_SUCCESS';
export const USERS_BLOCK_FAIL= 'USERS_BLOCK_FAIL';

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST'
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL'


export const USER_LOGOUT= 'USER_LOGOUT';


