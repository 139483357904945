import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector, useDispatch } from "react-redux";
import { verifyUser } from "../actions/userActions";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display:'flex',
    flexFlow:'column',
    height:'25%',
    alignItems:'space-around',
    justifyContent:'center'
  },
  verifcationModal:{
      display:'flex',
      justifyContent:'center',
  },
  input:{
    margin:'10px',
    height:'35px',
    width:'65px',
    border:'none',
    borderRadius:'5px',
    textAlign:'center',
    fontFamily:'arimo',
    fontSize:'1.1rem',
    background:'#eef2f3'
  },
  button:{
      width:'150px',
      height:'40px',
      margin:'25px auto 0px auto',
      fontFamily:'arimo',
      fontSize:'1.1rem',
      border:'none',
      borderRadius:'5px',
      letterSpacing:'2px',
      cursor:'pointer'
  }
  
}));

export default function ModalVerification(props) {
  const classes = useStyles();
  const email = props.email;
  const [open, setOpen] = React.useState(true);
  const [first,setFirst] = React.useState(null)
  const [second,setSecond] = React.useState(null)
  const [third,setThird] = React.useState(null)
  const [fourth,setFourth] = React.useState(null)
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const redirect = "/"
  const userVerify = useSelector(state => state.userVerify);
  const { loading, userInfo, error, status } = userVerify;

  useEffect(() => { 
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
  }, [userInfo]);

  const getToken = ()=>{
      return first+second+third+fourth;
  }
  const dispatch = useDispatch();

  const submitHandler = e => {
    e.preventDefault();
    dispatch(verifyUser(email,getToken()));
  };

  return (
    <div className='screen-content'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Account Verifieren</h2>
            <form onSubmit={submitHandler}>
            <div className={classes.verifcationModal}>
                <input className={classes.input} onChange={e => setFirst(e.target.value)} type="text" maxLength="1"></input>
                <input className={classes.input} onChange={e => setSecond(e.target.value)} type="text" maxLength="1"></input>
                <input className={classes.input} onChange={e => setThird(e.target.value)} type="text" maxLength="1"></input>
                <input className={classes.input} onChange={e => setFourth(e.target.value)} type="text" maxLength="1"></input>
            </div>
            <button type="submit" className={classes.button} >Bevestig</button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}