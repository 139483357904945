import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';




import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveProduct, listProducts, deleteProduct } from '../actions/productAction';

import Popup from '../components/Popup';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    tableLayout: 'fixed',
    whiteSpace: 'nowrap'
  },
  button: {
    margin: theme.spacing(1)
  },
  createProduct:{
    margin:'3rem',
  }
}));

function ItemScreen(props) {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [unit, setUnit] = useState("");
  const [description, setDescription] = useState("");
  const[uploading,setUploading] = useState(false);

  const [openPopup, setOpenPopup] = useState(false);
  
  const productList = useSelector((state) => state.productList);
  const {products, loading, error} = productList;
 
  const productSave = useSelector((state) => state.productSave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave
  } = productSave;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete
  } = productDelete;
  
  const dispatch = useDispatch();
  useEffect(()=>{
    if(successSave){
      setModalVisible(false);
    }
    dispatch(listProducts());
     return ()=>{
 
     };
   },[successSave,successDelete])

  const openModal = (product) => {
    setModalVisible(true);
    setId(product._id);
    setName(product.name);
    setPrice(product.price);
    setDescription(product.description);
    setImage(product.image);
    setBrand(product.brand);
    setCategory(product.category);
    setCountInStock(product.countInStock);
    setUnit(product.unit)
  };

  const submitHandler = e => {
    e.preventDefault();
    dispatch(
      saveProduct(
      { _id: id,
        name,
        price,
        image,
        brand,
        category,
        countInStock,
        unit,
        description}
      )
    );
  };


  const deleteHandler = (product) => {
    dispatch(deleteProduct(product._id));
  };
  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    setUploading(true);
    axios
      .post('/api/uploads/s3', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setImage(response.data);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const [state, setState] = React.useState({
    columns: [
      { title: "Naam", field: "name" },
      { title: "Categorie", field: "category" },
      { title: "Prijs", field: "price" },
      { title: "Merk", field: "brand" },
      { title: "Aantal", field: "countInStock" },
      { title: "Eenheid", field: "unit" },
      
      {
        title: "Action",
        render: data => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => openModal(data)}
              className={classes.button}
              startIcon={<EditIcon/>}
            >
              Edit
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={() => deleteHandler(data)}
            >
              Delete
            </Button>
          </div>
        )
      }
    ]
  });


  return loading ? (
    <div>loading...</div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div >
      <div className="product-header">
        <Button
          type="submit"
          onClick={() => openModal({})}
          variant="contained"
          color="primary"
          className={classes.createProduct}
        >
          Request Item 
        </Button>
      </div>
      {modalVisible && (
        <div className="form screen-content">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>Request Item</h2>
              </li>
              <li>
                {loadingSave && <div>Loading...</div>}
                {errorSave && <div>{errorSave}</div>}
              </li>

              <li>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                  onChange={e => setName(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="price">Price</label>
                <input
                  type="text"
                  name="price"
                  value={price}
                  id="price"
                  onChange={e => setPrice(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="image">Image</label>
                <input
                  type="text"
                  name="image"
                  value={image}
                  id="image"
                  onChange={e => setImage(e.target.value)}
                ></input>
                <input type="file" onChange={uploadFileHandler}></input>
                {uploading && <div>Uploading...</div>}
              </li>
              <li>
                <label htmlFor="brand">Brand</label>
                <input
                  type="text"
                  name="brand"
                  value={brand}
                  id="brand"
                  onChange={e => setBrand(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="countInStock">CountInStock</label>
                <input
                  type="text"
                  name="countInStock"
                  value={countInStock}
                  id="countInStock"
                  onChange={e => setCountInStock(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="countInStock">Eenheid</label>
                <input
                  type="text"
                  name="unit"
                  value={unit}
                  id="unit"
                  onChange={e => setUnit(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="name">Category</label>
                <input
                  type="text"
                  name="category"
                  value={category}
                  id="category"
                  onChange={e => setCategory(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  value={description}
                  id="description"
                  onChange={e => setDescription(e.target.value)}
                ></textarea>
              </li>
              <li>
                <button type="submit" className="button primary">
                  {id ? "Update" : "Create"}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setModalVisible(false)}
                  className="button secondary"
                >
                  Back
                </button>
              </li>
            </ul>
          </form>
        </div>
      )}

      <div className="table-only">
        <MaterialTable
          title="All Products"
          columns={state.columns}
          data={products}
          options={{ exportButton: true }}
        />
      </div>
    </div>
  );
}

export default ItemScreen;
