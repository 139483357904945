import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { SnackbarProvider } from 'notistack';


// import 'bootstrap/dist/css/bootstrap.min.css';
const stripePromise = loadStripe("pk_live_51HzwaAH8w5UYmCB0g6hsbqb1w5fcII2KO52HR0Yj7poLhbI8ftbGoI4K7PGzhci9ncQzvHfvX7qCsqcqvmcNvo4w00XNp6wHIH");

ReactDOM.render(
  <SnackbarProvider>
  <Elements stripe={stripePromise}>
  <Provider store = {store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>
  </Elements>
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
