import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red, blueGrey } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Stars from "./Stars";
import Fade from "react-reveal";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import Chip from "@material-ui/core/Chip";

import { addToCart } from "../actions/cartAction";
import { useDispatch } from "react-redux";
import { fontWeight } from "@material-ui/system";

const useStyles = makeStyles(theme => ({
  price: {
    color: "white",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    fontSize: "15px",
    fontFamily: "Montserrat, sans-serif",
  },
  button: {
    borderRadius: 50,
    margin: "8px",
    width: "96%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  },
  root: {
    maxWidth: "400px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    // fontFamily: "Montserrat, sans-serif",
  },
  media: {
    height: 0,
    paddingTop: "56.25%"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: blueGrey[500]
  }
}));

function Products(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAddToCart = (productId, productName, variant) => {
    dispatch(addToCart(productId, 1));
    enqueueSnackbar(`${productName} added in cart`, { variant });
  };

  const convertSrdToEuro = (srd, euro) =>{
    return `€ ${(srd / euro).toFixed(2)}`;
  }

  return (
    <Fade bottom cascasde>
      <Card className={classes.root}>
        <CardHeader title={props.data.name} subheader={props.data.category}  />
        <Link to={"/product/" + props.data._id}>
          <CardMedia className={classes.media} image={props.data.image} />
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary" component="p">
              {props.data.description.length > 50
                ? props.data.description.substring(0, 50) + "..."
                : props.data.description}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Chip
              size="large"
              // label={"€ " + props.data.price / props.rateEuro}
              label={convertSrdToEuro(props.data.price,props.rateEuro)}
              clickable
              color="primary"
              className={classes.price}
            />
            {/* SRD {props.data.price} */}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            ></IconButton>
            <Stars value={props.data.rating}/>
          </CardActions>
        </Link>
        <Button
          type="submit"
          className={classes.button}
          onClick={handleAddToCart.bind(
            this,
            props.data._id,
            props.data.name,
            "success"
          )}
          variant="contained"
          size="large"
          color="primary"
        >
          Add to cart
        </Button>
        <br />
      </Card>
    </Fade>
  );
}

export default Products;
