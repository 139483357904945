import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  list: {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function PrivacyPolicy(props) {
  const classes = useStyles();

  return (
    <div className="screen-content">
      <div className={classes.root}>
        <Typography variant="h2" gutterBottom>
          Privacy Policy
        </Typography>
        <Accordion>
          <AccordionDetails>
            <div>
              <Typography className={classes.sectionTitle}>
                <strong>Privacy Policy</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                This privacy policy applies to the suriwebwinkel app (hereby
                referred to as "Application") for mobile devices that was
                created by Shiva Bhaggan (hereby referred to as "Service
                Provider") as a Free service. This service is intended for use
                "AS IS".
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Information Collection and Use</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                The Application collects information when you download and use
                it. This information may include:
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  Your device's Internet Protocol address (e.g. IP address)
                </li>
                <li className={classes.listItem}>
                  The pages of the Application that you visit, the time and date
                  of your visit, the time spent on those pages
                </li>
                <li className={classes.listItem}>
                  The time spent on the Application
                </li>
                <li className={classes.listItem}>
                  The operating system you use on your mobile device
                </li>
              </ul>

              <Typography className={classes.paragraph}>
                The Application does not gather precise information about the
                location of your mobile device.
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Third Party Access</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                Only aggregated, anonymized data is periodically transmitted to
                external services to aid the Service Provider in improving the
                Application and their service. The Service Provider may share
                your information with third parties in the ways that are
                described in this privacy statement.
              </Typography>
              <Typography className={classes.paragraph}>
                Please note that the Application utilizes third-party services
                that have their own Privacy Policy about handling data. Below
                are the links to the Privacy Policy of the third-party service
                providers used by the Application:
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Google Play Services
                  </a>
                </li>
              </ul>

              <Typography className={classes.sectionTitle}>
                <strong>Opt-Out Rights</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                You can stop all collection of information by the Application
                easily by uninstalling it. You may use the standard uninstall
                processes as may be available as part of your mobile device or
                via the mobile application marketplace or network.
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Data Retention Policy</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                The Service Provider will retain User Provided data for as long
                as you use the Application and for a reasonable time thereafter.
                If you'd like them to delete User Provided Data that you have
                provided via the Application, please contact them at
                shivabhaggan@gmail.com and they will respond in a reasonable
                time.
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Children</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                The Service Provider does not use the Application to knowingly
                solicit data from or market to children under the age of 13.
              </Typography>
              <Typography className={classes.paragraph}>
                The Application does not address anyone under the age of 13. The
                Service Provider does not knowingly collect personally
                identifiable information from children under 13 years of age. In
                the case the Service Provider discovers that a child under 13
                has provided personal information, the Service Provider will
                immediately delete this from their servers. If you are a parent
                or guardian and you are aware that your child has provided us
                with personal information, please contact the Service Provider
                at shivabhaggan@gmail.com so that they will be able to take the
                necessary actions.
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Security</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                The Service Provider is concerned about safeguarding the
                confidentiality of your information. The Service Provider
                provides physical, electronic, and procedural safeguards to
                protect information the Service Provider processes and
                maintains.
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Changes</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                This Privacy Policy may be updated from time to time for any
                reason. The Service Provider will notify you of any changes to
                the Privacy Policy by updating this page with the new Privacy
                Policy. You are advised to consult this Privacy Policy regularly
                for any changes, as continued use is deemed approval of all
                changes.
              </Typography>

              <Typography className={classes.paragraph}>
                This privacy policy is effective as of 2024-08-22.
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Your Consent</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                By using the Application, you are consenting to the processing
                of your information as set forth in this Privacy Policy now and
                as amended by us.
              </Typography>

              <Typography className={classes.sectionTitle}>
                <strong>Contact Us</strong>
              </Typography>
              <Typography className={classes.paragraph}>
                If you have any questions regarding privacy while using the
                Application, or have questions about the practices, please
                contact the Service Provider via email at
                shivabhaggan@gmail.com.
              </Typography>

              <Typography className={classes.paragraph}>
                This privacy policy page was generated by
                <a
                  href="https://app-privacy-policy-generator.nisrulz.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  App Privacy Policy Generator
                </a>
                .
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
