import React, { useEffect } from "react";
import { addToCart, removeFromCart } from "../actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import ShopIcon from "@material-ui/icons/Shop";
import { AwesomeButton } from "react-awesome-button";

import { getLatestRate } from "../actions/rateAction";

const useStyles = makeStyles(theme => ({
  select: {
    marginBottom: "10px"
  },
  label: {
    marginTop: "10px"
  },
  button: {
    height: "5rem",
    fontSize: "15px",
    marginBottom: "5px",
  },
  deleteButton: {
    height: "3rem"
  },
  chip: {
    width: "100%",
    height: "3rem",
    fontSize: "15.5px"
  }
}));

function CartScreen(props) {
  const classes = useStyles();

  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;

  const mostLatestRate = useSelector(state => state.rateLatest);
  const { latestRate } = mostLatestRate;

  const productId = props.match.params.id;
  const qty = props.location.search
    ? Number(props.location.search.split("=")[1])
    : 1;
  const dispatch = useDispatch();

  const removeFromCartHandler = productId => {
    dispatch(removeFromCart(productId));
  };

  const checkoutHandler = () => {
    props.history.push("/signin?redirect=shipping");
  };

  function handleReturnToProductScreen(event) {
    props.history.push("/");
  }

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, []);
  return (
    <div className="cart screen-content">
      <div className="cart-list">
        <Fade left cascade>
          <ul className="cart-list-container">
            <li>
              <h3>Shopping Cart</h3>
              <div>Price</div>
            </li>
            {cartItems.length === 0 ? (
              <div>Cart is empty</div>
            ) : (
              cartItems.map(item => (
                <li>
                  <div className="cart-image">
                    <img src={item.image} alt="product" />
                  </div>
                  <div className="cart-name">
                    <div>
                      <Link to={"/product/" + item.product}>{item.name}</Link>
                    </div>
                    <div>
                      <InputLabel
                        className={classes.label}
                        id="demo-controlled-open-select-label"
                      >
                        Quantity:{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item.qty}
                        onChange={e =>
                          dispatch(addToCart(item.product, e.target.value))
                        }
                        className={classes.select}
                      >
                        {[...Array(item.countInStock).keys()].map(x => (
                          <MenuItem value={x + 1}>{x + 1}</MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => removeFromCartHandler(item.product)}
                      className={classes.deleteButton}
                      startIcon={<DeleteIcon />}
                    >
                      Remove
                    </Button>
                  </div>
                  <div className="cart-price">
                  € {(item.price.toFixed(2) * item.qty).toFixed(2)} 
                  </div>
                </li>
              ))
            )}
          </ul>
        </Fade>
      </div>
      <div className="cart-action">
        <h3>
          <Chip
            size="medium"
            label={
              "Subtotal (" +
              cartItems.reduce((a, c) => a + c.qty, 0) +
              " items) : € " +
              cartItems.reduce((a, c) => a + c.price * c.qty, 0).toFixed(2)
            }
            color="secondary"
            clickable
            className={classes.chip}
          />
        </h3>

        <Button
          onClick={checkoutHandler}
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
        >
          Bestellen
        </Button>
        {/* <AwesomeButton
          type="primary"
          action={checkoutHandler}
          className={classes.button}
        >
          GA NAAR KASSA
        </AwesomeButton> */}

        <Button
          variant="outlined"
          size="medium"
          color="primary"
          onClick={handleReturnToProductScreen}
          startIcon={<ShopIcon />}
          className={classes.margin}
          fullWidth
        >
          doorgaan met winkelen
        </Button>
      </div>
    </div>
  );
}

export default CartScreen;
