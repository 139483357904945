import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import { saveShipping } from "../actions/cartAction";
import { useDispatch } from "react-redux";
import Stepper from "../components/Stepper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
  address: {
    fontSize: "16px"
  },
  formControl: {
    minWidth: 120
  }
}));

function ShippingScreen(props) {
  const classes = useStyles();
  const [address, setAddress] = useState("");
  const [resort, setResort] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [district, setDistrict] = useState("");
  const [pickUp, setPickUp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [required, setRequired] = useState(true);

  const dispatch = useDispatch();

  const submitHandler = e => {
    e.preventDefault();
    dispatch(
      saveShipping({
        address,
        city,
        postalCode,
        country,
        district,
        resort,
        pickUp
      })
    );
    props.history.push("payment");
  };

  const handlePickUp = () => {
    if (!pickUp) {
      setDisabled(true);
      setPickUp(true);
      setRequired(false);
    } else {
      setPickUp(false);
      setDisabled(false);
      setPickUp(false);
      setRequired(true);
    }
  };

  return (
    <div className="screen-content">
      <Stepper step="1"></Stepper>
      <div className="form ">
        <form onSubmit={submitHandler}>
          <ul className="form-container box-shadow">
            <li>
              <h2>Verzending</h2>
            </li>
            <li>
              <FormControlLabel
                control={
                  <Switch
                    checked={pickUp}
                    color="primary"
                    onChange={handlePickUp}
                    name="gilad"
                  />
                }
                label="Ophalen (Doebestraat #3)"
              />
            </li>
            <li>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Land
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                  label="District"
                  required={required}
                  disabled={disabled}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Suriname"}>Suriname</MenuItem>
                </Select>
              </FormControl>
            </li>
            <li>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  District
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={district}
                  onChange={e => setDistrict(e.target.value)}
                  label="District"
                  required={required}
                  disabled={disabled}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Paramaribo"}>Paramaribo</MenuItem>
                  <MenuItem value={"Wanica"}>Wanica</MenuItem>
                  <MenuItem value={"Nickerie"}>Nickerie</MenuItem>
                  <MenuItem value={"Para"}>Para</MenuItem>
                </Select>
              </FormControl>
            </li>
            <li>
              <TextField
                label="Adres"
                type="text"
                variant="outlined"
                required={required}
                className={classes.address}
                onChange={e => setAddress(e.target.value)}
                disabled={disabled}
              />
            </li>
            <li>
              <TextField
                label="Resort"
                type="text"
                variant="outlined"
                required={required}
                className={classes.address}
                onChange={e => setResort(e.target.value)}
                disabled={disabled}
              />
            </li>
            {/* <li>
              <TextField
                label="City"
                type="text"
                variant="outlined"
                required
                onChange={e => setCity(e.target.value)}
              />
            </li> */}
            {/* <li>
              <TextField
                label="Postal Code"
                type="text"
                variant="outlined"
                required
                onChange={e => setPostalCode(e.target.value)}
              />
            </li> */}
            {/* <li>
              <TextField
                label="Land"
                type="text"
                variant="outlined"
                required
                onChange={e => setCountry(e.target.value)}
              />
            </li> */}
            <li>
              <Button type="submit" variant="contained" color="primary">
                Continue
              </Button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
}

export default ShippingScreen;
