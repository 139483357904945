import React from "react";
import { useEffect, useState } from "react";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-c137.css";
import TextField from "@material-ui/core/TextField";
import Axios from "../configuration/axiosConfiguration";
import { useParams } from "react-router-dom";

const ChangePasswordScreen = (props) => {
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const { token } = useParams();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (password === retypePassword) {
        const { status, data } = await Axios.post(
          "/api/users/change-password-offline",
          { newPassword: password },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (status === 200) {
          alert(
            "Uw wachtwoord is zojuist veranderd, u kunt opnieuw proberen in te loggen op de suriwebwinkel app"
          );
        }
      } else {
        setPassword("");
        setRetypePassword("");
        alert(
          "Uw wachtwoord komt niet overeen, aub controleren en opnieuw proberen."
        );
      }
    } catch (exception) {
      alert(
        "Uw wachtwoord is helaas nog niet veranderd, stuur ons een screenshot van deze melding op./API"
      );
    }
  };

  return (
    <div className="form body screen-content ">
      <form onSubmit={submitHandler}>
        <ul className="form-container box-shadow">
          <li>
            <h2>Wachtwoord Veranderen</h2>
          </li>
          <li>
            <TextField
              id="password"
              type="password"
              label="Nieuw wachtwood"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </li>
          <li>
            <TextField
              id="password"
              type="password"
              label="Nogmaals het nieuw wachtwood"
              variant="outlined"
              onChange={(e) => setRetypePassword(e.target.value)}
              value={retypePassword}
              required
            />
          </li>
          <li>
            <AwesomeButton type="primary">Wachtwoord Veranderen</AwesomeButton>
          </li>
        </ul>
      </form>
    </div>
  );
};

export default ChangePasswordScreen;
