import Axios from "../configuration/axiosConfiguration";
import Cookie from "js-cookie";
import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USERS_ALL_REQUEST,
  USERS_ALL_SUCCESS,
  USERS_ALL_FAIL,
  USER_BLOCK_REQUEST,
  USERS_BLOCK_SUCCESS,
  USERS_BLOCK_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAIL,
} from "../constants/userConstants";
import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
} from "../constants/userConstants";

const listUsers = () => async (dispatch, getState) => {
  const {
    userSignin: { userInfo },
  } = getState();
  dispatch({ type: USERS_ALL_REQUEST });
  try {
    const { data } = await Axios.get("api/users/all", {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: USERS_ALL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USERS_ALL_FAIL, payload: error.message });
  }
};

const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post("/api/users/signin", { email, password });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    Cookie.set("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload: error.response.data.msg,
      status: error.response.status,
    });
  }
};

const verifyUser = (email, token) => async (dispatch) => {
  dispatch({ type: USER_VERIFY_REQUEST, payload: { email, token } });
  try {
    const { data } = await Axios.post("/api/users/verify", { email, token });
    dispatch({ type: USER_VERIFY_SUCCESS, payload: data });
    Cookie.set("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_VERIFY_FAIL,
      payload: error.response.data.msg,
      status: error.response.status,
    });
  }
};

const register = (name, email, password) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { name, email, password } });
  try {
    const { data } = await Axios.post("/api/users/register", {
      name,
      email,
      password,
    });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    Cookie.set("userInfo", JSON.stringify(data));
  } catch (error) {
    console.log(JSON.stringify(error));
    dispatch({ type: USER_REGISTER_FAIL, payload: error.response.data.msg });
  }
};

const blockUser = (userId) => async (dispatch, getState) => {
  const {
    userSignin: { userInfo },
  } = getState();
  dispatch({ type: USER_BLOCK_REQUEST, payload: { userId } });
  try {
    const { data } = await Axios.put("/api/users/block/" + userId, null, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: USERS_BLOCK_SUCCESS, payload: data });
  } catch (err) {
    console.log(err);
    dispatch({ type: USERS_BLOCK_FAIL, payload: err.message });
  }
};

const update =
  ({ userId, name, email, password }) =>
  async (dispatch, getState) => {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({
      type: USER_UPDATE_REQUEST,
      payload: { userId, name, email, password },
    });
    try {
      const { data } = await Axios.put(
        "/api/users/" + userId,
        { name, email, password },
        {
          headers: {
            Authorization: "Bearer " + userInfo.token,
          },
        }
      );
      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
      Cookie.set("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({ type: USER_UPDATE_FAIL, payload: error.message });
    }
  };

const logout = () => (dispatch) => {
  Cookie.remove("userInfo");
  dispatch({ type: USER_LOGOUT });
};
export { signin, register, update, logout, listUsers, blockUser, verifyUser };
