import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {
  AwesomeButton
} from "react-awesome-button";
import TextField from "@material-ui/core/TextField";



import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../actions/userActions";

function RegisterScreen(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 
    const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";
  const userRegister = useSelector(state => state.userRegister);
  const { loading, userInfo, error } = userRegister;
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo) {
      props.history.push('signin');
    }
    return () => {
      //
    };
  }, [userInfo]);

  const submitHandler = e => {
    e.preventDefault();
    dispatch(register(name, email, password));
  };

  return (
    <div className="form screen-content">
      <form onSubmit={submitHandler}>
        <ul className="form-container box-shadow">
          <li>
            <h2>Registreren</h2>
          </li>
          <li>
            {loading && <div>Loading...</div>}
            {error && <Alert severity="error">{error}</Alert>}
          </li>
          <li>
          <TextField
              id="name"
              type="name"
              label="Naam"
              variant="outlined"
              onChange={e => setName(e.target.value)}
              required
            />
          </li>
          <li>
          <TextField
              id="email"
              type="email"
              label="Email"
              variant="outlined"
              onChange={e => setEmail(e.target.value)}
              required
            />
          </li>
          <li>
          <TextField
              id="password"
              type="password"
              label="Passwoord"
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              required
            />
          </li>
          <li>
          <TextField
              id="password"
              type="password"
              label="Voer paswoord opnieuw in"
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              required
            />
          </li>
          <li>
            <AwesomeButton type="primary">Registreren</AwesomeButton>
          </li>
          <li>
            Heeft u al een account?
            <Link
              to={redirect === "/" ? "signin" : "signin?redirect=" + redirect}
            >
              <li>
                <AwesomeButton type="primary">Aanmelden</AwesomeButton>
              </li>
            </Link>
          </li>
        </ul>
      </form>
    </div>
  );
}

export default RegisterScreen;
