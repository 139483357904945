import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, orange } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    width: '100%',
  }
});

const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#00897b',
    },
  },
});

function Loading() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 100;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={outerTheme}>
      <LinearProgress variant="determinate" value={progress} color="secondary" />
      </ThemeProvider>
    </div>
  );
}
 export default Loading;