import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Axios from "../configuration/axiosConfiguration";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  p: 10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const VendorPaymentModal = ({
  vendorPaymentModal,
  setVendorPaymentModal,
  administrationRequest,
}) => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([{ vendorName: "", amount: "" }]);
  const [exchangeRate, setExchangeRate] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setVendorPaymentModal(false);
    setOpen(false);
  };

  const addRow = () => {
    setRows([...rows, { vendorName: "", amount: "" }]);
  };

  const saveVendorPayment = async () => {
    // Logic to save vendor payments
    console.log("Exchange Rate:", exchangeRate);
    console.log("Vendor payments saved:", rows);
    try {
      const { data, status } = await Axios.put(
        `/api/orders/administration/${administrationRequest.orderId}`,
        {
          vendorPayments: rows,
          exchangeRate,
        },
        {
          headers: {
            Authorization: "Bearer " + administrationRequest.token,
          },
        }
      );
      alert(data.message);
    } catch (exception) {
      alert(exception.response.data.message);
    }

    handleClose();
  };

  React.useEffect(() => {
    if (vendorPaymentModal) {
      handleOpen();
    }
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h8"
            component="h2"
            sx={{ marginBottom: "20px" }}
          >
            Vendor Payments for {administrationRequest.orderId}
          </Typography>
          <TextField
            label="Exchange Rate"
            variant="outlined"
            value={exchangeRate}
            onChange={(e) => setExchangeRate(e.target.value)}
            sx={{ marginBottom: "20px" }}
          />
          {rows.map((row, index) => (
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              key={index}
              sx={{ marginBottom: "20px" }}
            >
              <Grid item>
                <TextField
                  label="Vendor Name"
                  variant="outlined"
                  value={row.vendorName}
                  onChange={(e) => {
                    const updatedRows = [...rows];
                    updatedRows[index].vendorName = e.target.value;
                    setRows(updatedRows);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Amount"
                  variant="outlined"
                  value={row.amount}
                  onChange={(e) => {
                    const updatedRows = [...rows];
                    updatedRows[index].amount = e.target.value;
                    setRows(updatedRows);
                  }}
                />
              </Grid>
              <Grid item sx={{ width: "56px" }}>
                {index > 0 && ( // Condition to render delete button for rows after the first one
                  <IconButton
                    onClick={() => {
                      const updatedRows = [...rows];
                      updatedRows.splice(index, 1);
                      setRows(updatedRows);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}

          <Box sx={{ marginBottom: "20px", display: "flex", gap: "10px" }}>
            <Button variant="contained" onClick={addRow}>
              More vendor
            </Button>
            <Button
              variant="contained"
              onClick={saveVendorPayment}
              sx={{
                bgcolor: "red",
                "&:hover": { bgcolor: "red" },
              }}
              disabled={
                rows.some(
                  (row) => row.vendorName === "" || row.amount === ""
                ) || exchangeRate === ""
              }
            >
              Save Vendor Payment
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default VendorPaymentModal;
