import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import { savePayment } from "../actions/cartAction";
import { useDispatch } from "react-redux";
import CheckoutSteps from "../components/CheckoutSteps";
import Stepper from '../components/Stepper';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';




function PaymentScreen(props) {
  const [paymentMethod, setPaymentMethod] = useState("");

  const dispatch = useDispatch();

  const submitHandler = e => {
    e.preventDefault();
    dispatch(savePayment({ paymentMethod }));   
    props.history.push("placeorder");
  };

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <div className="screen-content">
      <Stepper step="2"></Stepper>
      <div className="form">
        <form onSubmit={submitHandler}>
          <ul className="form-container box-shadow">
          <li>
              <h2>Betaling</h2>
            </li>
            <li>
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="payment method"
                    name="payment"
                    value={paymentMethod}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="iDeal"
                      control={<Radio color="primary"/>}
                      label="iDeal"
                    />
                    <FormControlLabel
                      value="Cash"
                      control={<Radio color="primary"/>}
                      label="Cash"
                    />
                    <FormControlLabel
                      value="PayPal"
                      control={<Radio color="primary"/>}
                      label="PayPal"
                      disabled
                    />
                    <FormControlLabel
                      value="Apple Pay"
                      control={<Radio color="primary" />}
                      label="Apple Pay"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </li>
            <li>
              <Button type="submit" variant="contained" color="primary">
              Continue
              </Button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
}

export default PaymentScreen;
