import React from "react";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signin } from "../actions/userActions";
import Alert from "@material-ui/lab/Alert";

import {
  AwesomeButton
} from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-c137.css";
import TextField from "@material-ui/core/TextField";
import ModalVerification from "../components/Modal";

function SigninScreen(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";
  const userSignin = useSelector(state => state.userSignin);
  const { loading, userInfo, error, status } = userSignin;
  const dispatch = useDispatch();
  useEffect(() => { 
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
  }, [userInfo]);

  const submitHandler = e => {
    e.preventDefault();
    dispatch(signin(email, password));
  };

  return (
    <div className="form body screen-content ">
      <div>
      {status === 403 && <ModalVerification history={props.history} email={email}/>}
      </div>
      <form onSubmit={submitHandler}>
        <ul className="form-container box-shadow">
          <li>
            <h2>Aanmelden</h2>
          </li>
          <li>
            {loading && <div>Loading...</div>}
            {error && <Alert severity="error">{error}</Alert>} 
          </li>
          <li>
            <TextField
              id="email"
              type="email"
              label="Email"
              variant="outlined"
              onChange={e => setEmail(e.target.value)}
              required
            />
          </li>
          <li>
          <TextField
              id="password"
              type="password"
              label="Password"
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              required
            />
          </li>
          <li>
            <AwesomeButton type="primary">Aanmelden</AwesomeButton>
          </li>
          <li>Nieuw bij Suriwebwinkel?</li>
          <li>
            <Link
              to={
                redirect === "/" ? "register" : "register?redirect=" + redirect
              }
            >
              <li>
                <AwesomeButton type="primary">Registreren</AwesomeButton>
              </li>
            </Link>
          </li>
        </ul>
      </form>
    </div>
  );
}

export default SigninScreen;
