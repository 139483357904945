import {createStore,combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {productListReducer, productDetailsReducer, productSaveReducer,productCategoryReducer, productDeleteReducer} from './reducer/productReducer';
import {cartReducer} from './reducer/cartReducers';
import {userSigninReducer, userRegisterReducer, userUpdateReducer,allUsersReducer,blockUserReducer, userVerifyReducer} from './reducer/userReducers'
import Cookie from 'js-cookie'
import { orderCreateReducer, orderDetailsReducer,
     orderPayReducer, myOrderListReducer, orderListReducer,
      orderDeleteReducer, orderUpdateStatusReducer
    } from './reducer/orderReducers';
import {getPaymentClientSecretReducer} from './reducer/paymentReducer'
import { rateListReducer, rateLatestReducer } from './reducer/rateReducers';

const cartItems = Cookie.getJSON("cartItems") || [];
const userInfo = Cookie.getJSON("userInfo") || null;

const initialState = {cart:{cartItems},userSignin:{userInfo}};
const reducer = combineReducers({
    productList:productListReducer,
    productDetails:productDetailsReducer,
    cart: cartReducer,
    userSignin: userSigninReducer,
    userRegister: userRegisterReducer,
    userVerify: userVerifyReducer,
    productSave:productSaveReducer,
    productDelete:productDeleteReducer,
    orderCreate:orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay:orderPayReducer,
    userUpdate:userUpdateReducer,
    myOrderList:myOrderListReducer,
    orderList:orderListReducer,
    orderDelete:orderDeleteReducer,
    orderUpdateStatus: orderUpdateStatusReducer,
    allUsers: allUsersReducer,
    blockUser: blockUserReducer,
    getPaymentClientSecret: getPaymentClientSecretReducer,
    productCategories: productCategoryReducer,
    rateList: rateListReducer,
    rateLatest: rateLatestReducer
})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer,initialState,composeEnhancer(applyMiddleware(thunk)));
export default store;