import React, { useState } from "react";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-c137.css";
import TextField from "@material-ui/core/TextField";
import Axios from "../configuration/axiosConfiguration";

const DeleteAccountScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await Axios.post(
        "/api/users/delete-account/permanently",
        {
          email,
          password,
        }
      );
      if (status === 200) {
        alert(
          "Your account deletion request has been submitted successfully. We will process it shortly."
        );
      }
    } catch (exception) {
      alert("Failed to request account deletion. Please try again later.");
    }
  };

  return (
    <div className="form body screen-content">
      <form onSubmit={submitHandler}>
        <ul className="form-container box-shadow">
          <li>
            <h2>Request Account Deletion</h2>
          </li>
          <li>
            <TextField
              id="email"
              type="email"
              label="Email Address"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </li>
          <li>
            <TextField
              id="password"
              type="password"
              label="Password"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </li>
          <li>
            <AwesomeButton type="primary">Request Deletion</AwesomeButton>
          </li>
        </ul>
      </form>
    </div>
  );
};

export default DeleteAccountScreen;
