export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_SAVE_REQUEST ="PRODUCT_SAVE_REQUEST";
export const PRODUCT_SAVE_SUCCESS ="PRODUCT_SAVE_SUCCESS";
export const PRODUCT_SAVE_FAIL ="PRODUCT_SAVE_FAIL";

export const PRODUCT_CATEGORY_REQUEST ="PRODUCT_CATEGORY_REQUEST";
export const PRODUCT_CATEGORY_SUCCESS ="PRODUCT_CATEGORY_SUCCESS";
export const PRODUCT_CATEGORY_FAIL ="PRODUCT_CATEGORY_FAIL";

export const PRODUCT_DELETE_REQUEST ="PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS ="PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL ="PRODUCT_DELETE_FAIL";

