import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import {
  AwesomeButton
} from 'react-awesome-button';

import { detailsProduct } from "../actions/productAction";
import {getLatestRate} from '../actions/rateAction'


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    paddingRight: "1rem"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

function ProductScreen(props) {
  const classes = useStyles();
  const [qty, setQty] = useState(1);
  const productDetails = useSelector(state => state.productDetails);
  const { product, loading, error } = productDetails;

  const mostLatestRate = useSelector(state => state.rateLatest);
  const { latestRate } = mostLatestRate;

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!latestRate.euro) {
      dispatch(getLatestRate());
    }
    dispatch(detailsProduct(props.match.params.id)); // Will run after componentDidMount (After rendering the page)
    return () => {
      //
    };
  }, []);

  const handleAddToCart = () => {
    props.history.push("/cart/" + props.match.params.id + "?qty="+qty);
  };

  const handleChange = event => {
    setQty(event.target.value);
  };

  const handleTest = ()=>{
    console.log('object')
  }

  const convertSrdToEuro = (srd, euro) =>{
    let amount = ((srd / euro).toFixed(2) * qty).toFixed(2);
    return `€ ${amount}`;
  }

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div className="details">
          <div className="screen-content">
            <div className="back-to-result">
              <Link to="/">
              <AwesomeButton type="primary">Terug</AwesomeButton>                
              </Link>
            </div>
          </div>
          <div className="details-image">
            <img src={product.image} alt="product"></img>
          </div>
          <div className="details-action">
            <h2 className="bold">Description</h2>
            <ul>
              <li>
                <div>
                  <b>{"Name: "}</b>
                  {product.name} 
                </div>
              </li>
              <li>
                <b>Price: </b> {convertSrdToEuro(product.price,(Number(latestRate.euro)))} {' '+product.unit?('Per '+ product.unit):''}    
              </li>
              <li>
              {userInfo && userInfo.isAdmin && (<><b>Price in SRD: </b><span>{product.price}</span></> )}
              </li>
              <li>
                <b>Description: </b>
                <div>{product.description}</div>
              </li>
              <li>
                <div>
                  <b>Status: </b>
                  {product.countInStock > 0 ? "In Stock" : "Unavailable"}
                </div>
              </li>
              <li>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Quantity
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={qty}
                    onChange={handleChange}
                  >
                    {[...Array(product.countInStock).keys()].map(x => (
                      <MenuItem value={x + 1}>{x + 1}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
              <li>
                {product.countInStock > 0 && (
                  <AwesomeButton type="primary" action={handleAddToCart}>Toevoegen in cart</AwesomeButton>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductScreen;
