import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";

const VendorPaymentList = ({ vendorPayments }) => {
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {vendorPayments &&
        vendorPayments.map((vendorPayment) => {
          const labelId = `checkbox-list-label-${vendorPayment}`;
          return (
            <ListItem
              key={vendorPayment._id}
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  SRD {vendorPayment.amount}
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemText
                  id={labelId}
                  primary={`${vendorPayment.vendorName}`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
};

export default VendorPaymentList;
